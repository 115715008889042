import React from "react";
import './about.css'

function About(){
   return( 
        <div className="about_section" id="about">
            <div className="about_data">
                <div className="title">Who We Are</div>
                <div className="body">
                Our team, a group of dedicated professionals, shares a collective understanding of the profound impact that a perfect finish can have on a space. We approach each drywall installation, repair, or renovation with an artist's precision, ensuring that every seam is invisible, and every surface is a testament to our pursuit of perfection.

                  </div>
                <div className="subbody">
                We go beyond the basics, selecting only the finest materials and employing cutting-edge techniques to bring your vision to life. Our attention to detail is our signature, evident in the painstaking taping, mudding, and sanding that goes into each and every project. We don't just aim to meet expectations; we aim to surpass them, leaving you with walls and ceilings that tell a story of our relentless pursuit of perfection.
                    </div>
                    <div className="conclude">
        Whether it's your home, your business, or a unique industrial space, our drywall company is distinguished by a personal commitment to perfection. We believe that each space is an opportunity for artistry, and we approach every project as a chance to turn your vision into a personal masterpiece. Choose our services, and you choose a partner who shares your passion for perfection.
                </div>
                <button className="contact_btn" onClick={() =>  window.location.href='#contact'}>Contact Us</button>
            </div>
            <div className="about_img"/>
            
        </div>
    )
}

export default About;