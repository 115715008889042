import React, { useRef, useState } from "react";
import Home from './Components/home';
import './App.css';
import Services from './Components/services';
import Contact from './Components/contact';
import Footer from './Components/footer';
import About from './Components/about';
import './Components/navbar.css';
function App() {
  const [open, setOpen] = useState(false);
  const serviceRef = useRef();
  const contactRef = useRef();
  const aboutRef = useRef();
 
  return (
    <div className="App">
      <div className="navbar">
          <span className="nav_logo" onClick={() => window.scrollTo({ top: 0, behavior: "smooth"})} >Vikar Drywall</span>
          <div className="nav_options">
              <span className="nav_service nav-op" onClick={() => serviceRef.current.scrollIntoView({behavior: 'smooth'})}>Services</span>
              <span className="nav_about nav-op" onClick={() => aboutRef.current.scrollIntoView({behavior: 'smooth'})}>About</span>
              <span className="nav_contact nav-op" onClick={() => contactRef.current.scrollIntoView({behavior: 'smooth'})}>Contact</span>
          </div>
          <div className="dropdown_menu">
              <div className="hamburger" onClick={()=>{setOpen(open=>!open)}}>
              <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAOUlEQVR4nO3UwQkAAAhCUUdv898InSwCH3RNCEmKCWb6HxD3gHKO7C1iIaCsJ4p7LLTISt715F1r1NKApJ5exy1UAAAAAElFTkSuQmCC"></img>
              </div>
              <div className={`dropdown_${open?"active":"inactive"}`}>
                  <ul>
                      <li onClick={() => {serviceRef.current.scrollIntoView({behavior: 'smooth'});setOpen(false)}}><span className="nav_service" >Services</span></li>
                      <li onClick={() => {aboutRef.current.scrollIntoView({behavior: 'smooth'});setOpen(false)}}><span className="nav_about" >About</span></li>
                      <li onClick={() => {contactRef.current.scrollIntoView({behavior: 'smooth'});setOpen(false)}}><span clasName="nav_contact">Contact</span></li>
                  </ul>
              </div>
          </div>
      </div>
      <Home/>
      <div ref={serviceRef}></div>
      <Services/>
      <div ref={aboutRef}></div>
      <About/>
      <div ref={contactRef}></div>
      <Contact/>
      <Footer/>
    </div>
  );
}

export default App;