import React from "react";
import "./services.css"
import drywall_service_1 from"../images/drywall_service_1.webp"
import drywall_service_2 from"../images/drywall_service_2.webp"
import drywall_service_3 from"../images/drywall_service_3.jpg"
import drywall_service_4 from"../images/drywall_service_4.jpg"
import drywall_service_5 from"../images/drywall_service_5.jpg"
function Services(){
    return(
        <div className="services" id="services">
            <div className="services_heading">
                Services
            </div>
            <div className="card_container">
                <div className="card_row">
                    <div className="card">
                        <div className="service_card_front">
                            <ServiceCardFront img={drywall_service_1} heading="Drywall Installation" />
                        </div>
                        <div className="service_card_back">
                            <ServiceCardBack heading="Drywall Installation" body="Rely on us to handle the entire process—from hanging the sheets to securing them in place and preparing the surface for subsequent steps. Experience the distinction of our dedicated drywall installation services, where a focus on quality craftsmanship and a commitment to excellence are always at the forefront."/>
                        </div>
                    </div>
                    <div className="card">
                        <div className="service_card_front">
                            <ServiceCardFront  img={drywall_service_2} heading="Insulation" />
                        </div>
                        <div className="service_card_back">
                            <ServiceCardBack heading="Insulation" body="We specialize in adding the perfect finishing touches to your interior spaces, ensuring a smooth and polished appearance. Our skilled craftsmen excel in the art of mudding, taping, and sanding to achieve flawless walls and ceilings. Whether you're completing a new construction project or renovating an existing space, our drywall finishing services promise to elevate the aesthetic appeal of your interiors."/>
                        </div>
                    </div>
                    <div className="card">
                        <div className="service_card_front">
                            <ServiceCardFront  img={drywall_service_3} heading="Drywall Renovation" />
                        </div>
                        <div className="service_card_back">
                            <ServiceCardBack heading="Drywall Renovation" body="Step into a world of transformation with our drywall renovation services. We're here to breathe new life into your spaces, whether it's a residential home or a commercial establishment. Our expert team excels in revitalizing interiors through meticulous drywall repairs, replacements, and upgrades. From addressing unsightly cracks to completely revamping walls, we handle it all with precision and care. With a focus on quality craftsmanship and attention to detail, our drywall renovation services ensure a fresh and polished look for your property. Elevate the aesthetic appeal and functionality of your space with our dedicated and reliable renovation expertise."/>
                        </div>
                    </div>
                </div>
                
                <div className="card_row">
                    <div className="card">
                        <div className="service_card_front">
                            <ServiceCardFront img={drywall_service_4} heading="Finishing" />
                        </div>
                        <div className="service_card_back">
                            <ServiceCardBack heading="Steel Framing" body="We specialize in the art of crafting durable and reliable steel frames for residential, commercial, and industrial projects. Whether you're building a new space or looking to reinforce an existing structure, our steel framing services deliver unparalleled strength, stability, and longevity."/>
                        </div>
                    </div>
                    <div className="card">
                        <div className="service_card_front">
                            <ServiceCardFront  img={drywall_service_5} heading="Steel Framing" />
                        </div>
                        <div className="service_card_back">
                            <ServiceCardBack heading="Insulation" body="Elevate your comfort and energy efficiency with our drywall insulation services. We specialize in enhancing the thermal performance of your spaces by expertly installing insulation within your walls. Our team ensures a seamless integration that not only regulates temperature but also reduces noise transmission, creating a more peaceful environment."/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

function ServiceCardFront(props){
    return(
        <div>
            
            <img className="card_img" src={props.img} width={300} height={200}alt={props.heading}/>
            <div className="card_caption">{props.heading}</div>
        </div>
            
    );
}
function ServiceCardBack(props){
    return(
        <div className="back_head">
            {props.heading}
        
            {/* <div className="back_body">{props.body}</div> */}
        </div>
    );
}


export default Services;