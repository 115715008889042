import React from "react";
import './footer.css';
import GoogleMapReact from 'google-map-react';

function Footer(){

    const pos_props= {
        lat: 49.120030,
        lng: -122.723270
    }
    const defaultProps = {
        center: {
          lat: pos_props.lat,
          lng: pos_props.lng
        },
        zoom: 15
    };
   
    function showMapLocation(){
        window.open("https://maps.google.com?q="+pos_props.lat+","+pos_props.lng );
    }

    function emailto(){
        window.location = 'mailto:info@vikardrywall.com';
    }
    
    return (
        <div className="footer_wrapper">
            <div className="company_profile">
                <div className="company_name">Vikar Drywall Ltd.</div>
                <div className="info">
                    <div className="contact_info">
                        <div className="email">
                            <img className="email_logo" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASCAYAAABWzo5XAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAvElEQVR4nN3TMU4CQRQG4C9QkJDICdhKDmCFp7D0ClzBkBizpR22nmK9hhUFHUKhhgNQ0JGsMRnIZLIu6xobXvIXM/Pel9cMZ18jrFH+MitcxtA1PltAHxjHUA8XeMK+AbDHLMx8zx7rAc8Y4AqvNcg8bNHHI+5jKA9NG9yigwm2EbDDHbq4wXu4z6ugQwoMkeElnLNwVyS9tVC6Qadiw8bQIYuQn94bQ+WJ/A80/QM0Tb/IWwtkmX6RM6wviVea5AjjJLcAAAAASUVORK5CYII="/>
                            <div className="contact_email" onClick={emailto}>info@vikardrywall.com</div>
                        </div>
                        <div className="contact_phone">
                            <img className="phone_logo" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASCAYAAABWzo5XAAAACXBIWXMAAAsTAAALEwEAmpwYAAAApElEQVR4nM3TMQ6CUAyA4V8nOI6OXIDVcBTDoCcAEtjxBA5srg5uegBPIOcgYF5Sk5eGQfoWmjR505f2tYU1Rw3cvTxYoRsweVlaoYuCrlborKCXFdorqLBCG6AXpCEwjsAIJKFQJFW9gTgUS4EB6IBtKJbLX3Wqsgp4ymq4d/YvNkibiQxgUvlY0uZnBlgMIQNw0/ythhnyYwecgFZu0x36SuMLaFc4ePH/4p8AAAAASUVORK5CYII="/>
                            <a className="phone"href="tel: +1 2368833379"> +1 (778) 384-2731</a>
                        </div>
                    </div>

                    <div className="address" onClick={showMapLocation}>
                        
                            <img className="add_logo"src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAABUElEQVR4nOWUvUoDURCFvxcILhpBUbBQe8HKwiYGW7FUtDMhRd5ADfEBBHtBsJWAhSiIjRZaqU+gdtoYURt/kKhcOCtTbJa9u7HKgYGdv3Nm7+xe6AbkgQ3gCniVXQJ15TJhXoQ/bewFmMtC3hLRAVAAcrIZ4FC5VhqRfjP5akxdTTXPQJ+PQN1MHiIAyrKevygcqdaJJca1mgqG/M6c/a0RKSrmFp8Y4fHk5FciFuzeBAmFC0+MppoC+eUIgRXleuU/+ghcqKkoP9CxhOQ35ohmFTv3EVhX07aJOcKSzC55R7VrPgJjwDfwAQzF1A0Dn6odxRP7mmwzpmZLNQ1SYFKTvbWZbhx41588QUrsasLjiNyJcm4HqTGoa8ARLZj4krkiBsiIZUM2osU+KbZIh9AQ4Slwpuc9Oog88GB+tHvf2zMJpvTNfwHT/BOqsi7CL/HMbEFq6KbcAAAAAElFTkSuQmCC"/>
                            <div>
                                18016 64A avenue, Surrey, BC
                            </div>
                            
                    </div>
                </div>
            </div>
            <div className="timing_info">
                
                <div className="contact_timings">
                    <div className="heading">
                        Timings:
                    </div>
                    <div className="weekday_time">
                        <div> Monday-Friday</div>
                        <div>8am-6pm</div>
                    </div>
                    <div className="weekend_time">
                        <div> Saturday-Sunday</div>
                        <div>11am-7pm</div>
                    </div>
                </div>
                
            </div>

        </div>
    )
}
export default Footer;