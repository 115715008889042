import React, { useState } from "react";
import './contact.css'
import emailjs from '@emailjs/browser'

const REACT_APP_SERVICE_ID = "service_vikardrywall"
const REACT_APP_TEMPLATE_ID = "template_vikar_drywall"
const REACT_APP_PUBLIC_KEY = "YNhSGT0Ezyvz8Zguy"

function Contact(){
    const initial_state = {
        f_name: "",
        l_name: "",
        email: "",
        msg: ""
    }
    const [formData, setFormData] = useState(initial_state);
    function onKeyDown(keyEvent){
        if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
            keyEvent.preventDefault();
          }
    }
    function isValidEmail(email) {
        // Define a regular expression pattern for email validation.
        const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return pattern.test(email);
      }
    function sendEmail(e){
        e.preventDefault();
        console.log(e.target.email_from.value)
        if(e.target.email_from.value == "" || e.target.first_name.value == "" || e.target.message.value == ""){
            alert("Please fill all the required sections of the contact form");
        }
        else{
            if(!isValidEmail(e.target.email_from.value)){
                alert("Email is incorrect");
            }
            else{
                emailjs.send(REACT_APP_SERVICE_ID, REACT_APP_TEMPLATE_ID, formData, REACT_APP_PUBLIC_KEY)
                .then((result)=>{
                    alert("Message sent successfully!");
                    setFormData(initial_state); 
                }, (error)=>{
                    console.log(error.text);
                });
            }
        }
    }

    return(
        <div className="contact_page" id="contact">
            <div className="info_section">
                <div className="contact_heading">Contact Us</div>
                <div className="contact_body">
                    Send us a message and we'll get back to you as soon as we stop juggling drywall mud!       </div>
            </div>
            <div className="form_container">
                <form className="contact_form" onKeyDown={onKeyDown} onSubmit={sendEmail}>
                    <div className="input_container">
                        <legend className="form_title">
                            <span className="title">Name</span>
                            <span className="content_required">(required)</span>
                        </legend>
                        <div className="name-container">
                            <div className="fname">
                                <label for="first_name">First name</label>
                                <input type="text" className="first_name" id ="first_name" value={formData.f_name} onChange={(e) => setFormData({...formData, f_name: e.target.value})}></input>
                            </div>
                            <div className="lname">
                                <label for="last_name" >Last name</label>
                                <input type="text" className="last_name" id ="last_name" value={formData.l_name} onChange={(e) => setFormData({...formData, l_name: e.target.value})}></input>
                            </div>
                        </div>
                        <div className="email_container">
                            <label for="email">Email</label>
                            <span className="content_required">(required)</span>
                            <input type="text" className="email_from" id ="email_from" value={formData.email} onChange={(e) => setFormData({...formData, email: e.target.value})}></input>
                        </div>
                        <div className="message_container">
                            <label for="message">Message</label>
                            <span className="content_required">(required)</span>
                            <textarea  className="message" id ="message" value={formData.msg} onChange={(e) => setFormData({...formData, msg: e.target.value})}></textarea>
                        </div>
                    </div>
                    <button type="submit" className="submit_form">Send</button>

                </form>
            </div>

        </div>
    );
}

export default Contact;