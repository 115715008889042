import React, {useState} from "react";
import './home.css'
import './contact.css'
function Home(){
    const [carousel_img, setcarousel_img] = useState(0);
   
     function onclick(id) {
        document.getElementById('zero').style.backgroundColor = "rgb(224, 222, 222)";
        document.getElementById('one').style.backgroundColor = "rgb(224, 222, 222)";
        document.getElementById(id).style.backgroundColor = 'black';
       
     }
    return(
        <div className="home" id="home">
            <div className={`photoView ${carousel_img==0?"one":"two"}`}>
                <div className="home_heading">Vikar Drywall Ltd.</div>
                <br/>
                <div className="home_slogan">Building Dreams,<span className="slogan_part_2">One Wall at a Time</span></div>
                <div className="home_services">Drywall &nbsp;&nbsp; Insulation <br/><br/> Steel stud &nbsp;&nbsp; Framing</div>
                
                <button className="home_contact_btn" onClick={() =>  window.location.href='#contact'}>Contact Us</button>
                
            </div>
            <div className="selector">
                <div className="carousel one" id= "zero"onClick={()=>{ onclick("zero"); setcarousel_img(0);}}/>
                <div className="carousel two" id= "one"onClick={()=>{ onclick("one"); setcarousel_img(1);}}/>
            </div>
            
        </div>
    );
}
export default Home;